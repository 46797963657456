import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import t1 from '../assets/t1.jpg'
import t2 from '../assets/t2.jpg'
 
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const osL = () => {
    window.open("#");
}

const tweet = () => {
    window.open("#");
}

const tg = () => {
    window.open("#");
}

const mail = () => {
    window.open("mailto:info@zodiacpuppy.com");
}

class Team extends Component {

    render() {
        return (

            <div class="boxWrapTeam">
                <div class="conT2">TEAM</div>
                <div class="teamMain">
                    <div class="team">
                        <img src={t2} />
                        <div class="nameT">NFTYMOS</div>
                        <div class="positionT">FOUNDER/CEO</div>
                    </div>

                    <div class="team">
                        <img src={t1} />
                        <div class="nameT">HERMES</div>
                        <div class="positionT">COMMUNITY MANAGER</div>
                    </div>
                </div>

             
            </div>
        )
    }
}

export default Team;

